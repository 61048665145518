// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-fireplace-design-js": () => import("./../../../src/pages/services/fireplace-design.js" /* webpackChunkName: "component---src-pages-services-fireplace-design-js" */),
  "component---src-pages-services-flue-liners-chimney-sweeping-js": () => import("./../../../src/pages/services/flue-liners-chimney-sweeping.js" /* webpackChunkName: "component---src-pages-services-flue-liners-chimney-sweeping-js" */),
  "component---src-pages-services-gas-bio-fuel-fireplace-burners-js": () => import("./../../../src/pages/services/gas-bio-fuel-fireplace-burners.js" /* webpackChunkName: "component---src-pages-services-gas-bio-fuel-fireplace-burners-js" */),
  "component---src-pages-services-gas-fireplace-installers-js": () => import("./../../../src/pages/services/gas-fireplace-installers.js" /* webpackChunkName: "component---src-pages-services-gas-fireplace-installers-js" */),
  "component---src-pages-services-gas-stoves-installers-js": () => import("./../../../src/pages/services/gas-stoves-installers.js" /* webpackChunkName: "component---src-pages-services-gas-stoves-installers-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

